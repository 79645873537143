import { BehaviorSubject } from 'rxjs'
import { ConfigType } from '../types'

export const devConfig = new BehaviorSubject<ConfigType | null>(null)

export function loadConfig() {
    let json: ConfigType = { ok: false }
    try {
        json = require('../constants/myconfig.json')
        json.ok = true
    } catch (e) {
        if ((e as any).code !== 'MODULE_NOT_FOUND') {
            throw e
        } else {
            json.ok = false
        }
    }
    devConfig.next(json)
}
