import React, { useState, useRef, useEffect } from 'react'

export interface CodeEntryBoxProps {
    length: number
    value: string
    resetValue: any
}

const CodeEntryBox = (props: CodeEntryBoxProps) => {
    const [secret, setSecret] = useState(props.value)
    function onClick(event: any): any {
        props.resetValue(secret)
        event.preventDefault()
    }
    const refs: React.MutableRefObject<any>[] = []
    const buttonRef = useRef(null)
    refs[props.value.length] = buttonRef

    useEffect(() => {
        refs[0].current?.focus()
    }, [])

    return (
        <form onSubmit={onClick}>
            {secret.split('').map((c, i) => {
                return <SingleCell source={secret} index={i} resetSource={setSecret} key={i} refs={refs} />
            })}
            <br />
            <input ref={buttonRef} type="submit" value="Submit" />
        </form>
    )
}

function SingleCell(props: { source: string; index: number; resetSource: (s: string) => void; refs: any }): any {
    function onChange(event: any): any {
        console.log('onChange', event)
        const newChar = event.target.value[0] || ' '
        const newSource = props.source.substr(0, props.index) + newChar + props.source.substr(props.index + 1)
        if (event.target.value.length === 1) {
            props.refs[props.index + 1].current?.focus()
        }
        console.log(newSource)
        props.resetSource(newSource)
    }

    function onKeyDown(event: any): any {
        console.log('onKeyDown', event)
        const ref = props.refs[props.index]
        if (event.key === 'Backspace' && props.index > 0 && ref.current?.value.length === 0) {
            props.refs[props.index - 1].current?.focus()
        }
    }

    const ref = useRef(null)
    props.refs[props.index] = ref
    const val = props.source[props.index] === ' ' ? '' : props.source[props.index]
    return (
        <input
            ref={ref}
            type="text"
            value={val}
            maxLength={1}
            style={boxStyle}
            onChange={onChange}
            onKeyDown={onKeyDown}
        />
    )
}
const fontWeight: 'bold' = 'bold'
const textAlign: 'center' = 'center'

const boxStyle = {
    border: 'solid',
    borderRadius: 2,
    height: '40px',
    width: '24px',
    margin: '5px',
    backgroundColor: '#faf5dc',
    textAlign,
    fontWeight,
    fontSize: '18pt',
    fontFamily: 'Courier New',
}

export default CodeEntryBox
