import { BehaviorSubject } from 'rxjs'
import {
    LoggedInUser,
    signalWebsocket,
    websocket,
    WebSocketMessage,
    WebsocketProtocolSession,
} from '@privacyresearch/sdk-typescript'
import { devConfig } from '../lib/loadconfig'

export const userSubject = new BehaviorSubject<LoggedInUser | null>(null)
export const sessionIDSubject = new BehaviorSubject<string>('')
export const sessionSubject = new BehaviorSubject<WebsocketProtocolSession | null>(null)

export function webAppSignallAddress(): string {
    return `${sessionIDSubject.value}-rp`
}

export function authAppSignallAddress(): string {
    return `${sessionIDSubject.value}-aa`
}

export const websocketMessageOrNull = new BehaviorSubject<WebSocketMessage | null>(null)
export const signalWebsocketMessageOrNull = new BehaviorSubject<WebSocketMessage | null>(null)

export function initializeWebsocketSubjects(): void {
    websocket.subscribe({
        next: (wsm: WebSocketMessage) => websocketMessageOrNull.next(wsm),
    })

    signalWebsocket.subscribe({
        next: (wsm: WebSocketMessage) => signalWebsocketMessageOrNull.next(wsm),
    })
}

userSubject.subscribe({
    next: async (user: LoggedInUser | null) => {
        if (user && devConfig.value?.USER_DB_URL) {
            const { username, pseudonym, preKeyBundle } = user
            const body = { username, address: pseudonym, preKeyBundle }
            const result = await fetch(devConfig.value!.USER_DB_URL!, {
                method: 'post',
                // headers: { 'x-api-key': apikey },
                body: JSON.stringify(body),
            })
            const pp = await result.json()
            console.log(pp)
        }
    },
})
