import React, { useEffect } from 'react'
import './App.css'
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { loadConfig, devConfig } from './lib/loadconfig'
import SensitiveComponent from './containers/sensitive-component'
import { useObservable } from './lib/useobservable'
import {
    initializeSignalWebsocket,
    initializeWebsocket,
    Protocol,
    signalStore,
    WebsocketProtocolSession,
} from '@privacyresearch/sdk-typescript'
import { initializeWebsocketSubjects, sessionSubject } from './state/subjects'
import { isMobile } from 'react-device-detect'

// load the developer-specific configuration of API Keys etc
loadConfig()

function App() {
    const configData = useObservable(devConfig, null)
    useEffect(() => {
        if (configData) {
            console.log(`loaded configData`)

            initializeWebsocket(configData.PROVISIONING_WS_URI || '')
            initializeSignalWebsocket(configData.SIGNAL_WS_URI || '')
            initializeWebsocketSubjects()
            const session = new WebsocketProtocolSession(
                signalStore,
                ['sub', 'avatar', 'username'],
                isMobile ? Protocol.QRBasic : configData?.Protocol || Protocol.QRBasic,
                `${configData.PROVER_URL}/sign`
            )
            sessionSubject.next(session)
        }
    }, [configData])

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <p>Privacy Research LLC Reference Web Implementation.</p>
                    name:{configData?.RELYINGPARTY_NAME}
                    <br />
                    id:{configData?.RELYINGPARTY_ID}
                    <br />
                    url:{configData?.RELYINGPARTY_URL}
                    <br />
                    logoURL:{configData?.RELYINGPARTY_LOGOURL}
                    <br />
                    publicKeyID:{configData?.RELYINGPARTY_PUBLICKEYID}
                    <br />
                    <hr />
                    <Link to="/">Home</Link>
                    <Link to="/sensitive">Click to Show Sensitive Information</Link>
                    <Switch>
                        <Route path="/sensitive">
                            <SensitiveComponent />
                        </Route>

                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </header>
            </div>
        </Router>
    )
}

function Home() {
    return <h2>Home</h2>
}

export default App
