import React from 'react'
import CodeEntryBox from './code-entrybox'

export default function GetSecret(
    props: { secret: string; setSecret: (s: string) => void }
    //  secret: string,
    // setSecret: (s: string) => void
): JSX.Element {
    return (
        <div>
            <h2>Type code you see on your phone here</h2>
            <CodeEntryBox length={4} value={props.secret} resetValue={props.setSecret} />
        </div>
    )
}
