import { AuthorizationRequestMessage, Protocol, RelyingPartyInfo } from '@privacyresearch/sdk-typescript'
import { v4 as uuid } from 'uuid'

export function createAuthorizationRequest(description: string, rpData: RelyingPartyInfo): AuthorizationRequestMessage {
    const iss: RelyingPartyInfo = rpData
    const requestMessage: AuthorizationRequestMessage = {
        type: 'authorize',
        messageID: uuid(),
        timestamp: Date.now(),
        request: {
            id: uuid(),
            type: 'authorization',
            iss,
            protocol: Protocol.QRBasic, // TODO: this isn't a QR protocol! Maybe move some stuff to LoginRequest
            requestedFields: [],
            description,
        },
    }
    return requestMessage
}
