import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode.react'

import { AuthProtocolState, WebSocketMessage } from '@privacyresearch/sdk-typescript'

import { useObservable } from '../lib/useobservable'
import GetSecret from './get-secret'

import { sessionIDSubject, sessionSubject, userSubject } from '../state/subjects'
import { MobileView } from 'react-device-detect'
import { BehaviorSubject } from 'rxjs'

export interface LoginProps {
    websocket: BehaviorSubject<WebSocketMessage | null>
    signalWebsocket: BehaviorSubject<WebSocketMessage | null>
}

export default function Login(props: LoginProps): JSX.Element {
    console.log(`render Login`)

    const [authState, setAuthState] = useState(AuthProtocolState.Uninitialized)
    const [secret, setSecret] = useState('    ')
    const [secretIsSet, setSecretIsSet] = useState(false)

    const session = useObservable(sessionSubject, null)

    const provisioningID = useObservable(sessionIDSubject, '')

    let deeplinkUrl = 'prauthapp://--/' + provisioningID

    // First work with the objects that should be present on first render
    useEffect(() => {
        if (session) {
            session.authStateSubject.subscribe({
                next: (state) => {
                    console.log({ state })
                    setAuthState(state)
                },
            })
            session.initializeHandshake().then(() => {
                sessionIDSubject.next(session.handshake.sessionID)
            })
        }
    }, [session])

    useEffect(() => {
        if (
            (authState === AuthProtocolState.Complete || authState === AuthProtocolState.UserAuthenticated) &&
            session
        ) {
            userSubject.next(session.handshake.verifiedUser)
        }
    }, [authState, session])

    const updateSecret = (s: string) => {
        if (!session) {
            throw new Error('attempting to set code without a protocol session')
        }
        setSecret(s)
        setSecretIsSet(true)
        session.handshake.code = s
    }

    switch (authState) {
        case AuthProtocolState.Uninitialized:
            return <div>Loading</div>
        case AuthProtocolState.Initialized:
            return (
                <div>
                    <h2>Scan this code to login</h2>
                    <QRCode value={provisioningID} />
                    <MobileView>
                        <br />
                        or click this link to open the Authentication App on your mobile phone:
                        <br />
                        <a href={deeplinkUrl}>GoTo authapp</a>
                    </MobileView>
                </div>
            )
        case AuthProtocolState.ProvisioningConnected:
            return <div>This Web App has connected with Mobile Auth App....Look at your phone</div>

        case AuthProtocolState.SecondFactorNeeded:
            return <GetSecret secret={secret} setSecret={updateSecret} />
        case AuthProtocolState.WaitingForProof:
            return <div>Waiting for Proof from phone</div>
        case AuthProtocolState.UserRejected:
            return <div>User Rejected</div>
        default:
            return <div>Connecting...</div>
    }
}
