import { useState, useEffect } from 'react'
import { Observable } from 'rxjs/internal/Observable'

export function useObservable<T>(observable: Observable<T>, initialValue: T): T {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        const subscription = observable.subscribe((newValue) => {
            setValue(newValue)
        })
        return () => subscription.unsubscribe()
    }, [observable])

    return value
}
