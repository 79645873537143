import React from 'react'
import Login from '../components/login'
import { signalWebsocketMessageOrNull, userSubject, websocketMessageOrNull } from '../state/subjects'
import { useObservable } from '../lib/useobservable'

export default function RequireAuthentication(props: any) {
    const user = useObservable(userSubject, null)
    if (user?.isLoggedIn) {
        return props.children
    }
    return <Login websocket={websocketMessageOrNull} signalWebsocket={signalWebsocketMessageOrNull} />
}
