import React, { useState, useEffect } from 'react'
import RequireAuthentication from '../components/require-authentication'
import { userSubject, sessionSubject } from '../state/subjects'
import { useObservable } from '../lib/useobservable'
import { isApprovedMessage, isRejectedMessage } from '@privacyresearch/sdk-typescript'
import { createAuthorizationRequest } from '../lib/requests'

export default function SensitiveComponent() {
    const user = useObservable(userSubject, null)

    const session = useObservable(sessionSubject, null)
    const [purchaseState, setPurchaseState] = useState('pre')

    useEffect(() => {
        if (session) {
            session.rawMessages.subscribe({
                next: (wsm) => {
                    const msg = JSON.parse(wsm)
                    if (isApprovedMessage(msg)) {
                        console.log("I'm approved! Set my state or sumthin")
                        setPurchaseState('approved')
                    } else if (isRejectedMessage(msg)) {
                        console.log("I'm rejected! Set my state or sumthin")
                        setPurchaseState('rejected')
                    }
                },
            })
        }
    }, [session])

    const username = user ? user.username : 'nobody'
    const keyBundle = JSON.stringify(user && user.preKeyBundle)

    const buySomething = () => {
        const authMsg = createAuthorizationRequest('Buy a piece of candy for $25', session!.handshake!.rpInfo!)
        console.log(`sending auth request`, { authMsg })
        session?.send(authMsg)
    }

    return (
        <RequireAuthentication>
            <h1>This is top secret stuff, {username}</h1>
            <div>{keyBundle}</div>
            {purchaseState === 'pre' ? (
                <button onClick={buySomething}>Buy Something</button>
            ) : purchaseState === 'approved' ? (
                <div>
                    Approved.
                    <br /> <button onClick={buySomething}>Buy Another?</button>
                </div>
            ) : (
                <div>
                    Rejected.
                    <br /> <button onClick={buySomething}>Want to try to buy again?</button>
                </div>
            )}
        </RequireAuthentication>
    )
}
